import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopy from '@mui/icons-material/ContentCopy';

import dayjs from 'dayjs';
import _ from 'lodash';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { PAYMENT_STATUS } from '../../utils/constants';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const OnlineBill = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const onlineOrder = useSelector((state) => state.OnlineOrder);
  const setting = useSelector((state) => state.setting);
  const history = useHistory();

  // Setting React hook form
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    const fetchProductTransaction = async () => {
      try {
        await dispatch(
          actions.onlineOrderAll({ name, page, size, billOrder: true }),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransaction();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(onlineOrder?.total);
    return () => {};
  }, [onlineOrder]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.onlineOrderDelete(id));
        await dispatch(
          actions.onlineOrderAll({ name, page, size, billOrder: true }),
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`/spm/online/${id}`);
  };

  const handleCopy = (id) => {
    const paymentLink = `${setting?.website_url}cart/${id}`;
    navigator.clipboard.writeText(paymentLink);
    setSnackBarOpen(true);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/spm/online/create">
        <Button startIcon={<AddBoxIcon />} variant="contained">
          เพิ่ม
        </Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่สร้าง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สถานะ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ลิงค์</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(onlineOrder.rows) ? (
                onlineOrder.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      {`${dayjs(row?.createdAt).format('D MMM BBBB ')}`}
                      <br />
                      {`${dayjs(row?.createdAt).format('HH:mm ')}`} น.
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.customer?.name}`}</div>
                    </TableCell>
                    <TableCell>
                      {row?.orders?.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1">
                          {_.isEmpty(eachOrder?.options) ? (
                            <div className="flex">
                              <div className="pr-1 text-red-500 font-bold">
                                {eachOrder?.quantity} X{' '}
                              </div>
                              <p className="font-bold">
                                {eachOrder?.product?.name}
                              </p>
                            </div>
                          ) : (
                            <div className="flex">
                              <p className="font-bold">
                                {_index + 1}
                                {' . '}
                                {eachOrder?.product?.name}
                              </p>
                              <p className="pl-2">
                                {eachOrder?.quantity}
                                {'  '}
                                {eachOrder?.product?.unit}
                              </p>

                              {/* {_.map(
                                eachOrder.options,
                                (eachOption, index2) => (
                                  <p className="pl-2 flex" key={index2}>
                                    - {eachOption.option}
                                    <p className="pl-2">
                                      {eachOption?.quantity}
                                      {'  '}
                                      {eachOrder?.product?.unit}
                                    </p>
                                  </p>
                                ),
                              )} */}
                            </div>
                          )}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {PAYMENT_STATUS[row?.status]?.description}
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        disabled={row?.status === 'successful'}
                        value={`${setting?.website_url}cart/${row?._id}`}
                        multiline
                      />
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'success'}
                          startIcon={<ContentCopy />}
                          size={'small'}
                          onClick={() => handleCopy(row.id)}
                          disabled={row?.status === 'successful'}
                        >
                          คัดลอก
                        </Button>
                        <Button
                          variant="contained"
                          color={'secondary'}
                          startIcon={<DescriptionIcon />}
                          size={'small'}
                          onClick={() =>
                            handleToInfoPage(row.paymenttransactions?._id)
                          }
                          disabled={
                            row?.status ===
                            PAYMENT_STATUS.รอการชำระเงิน.status_code
                          }
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            handleDelete(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderSnackbar = () => (
    <div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
        message="คัดลอกไปยังคลิปบอร์ดแล้ว"
      />
    </div>
  );

  if (onlineOrder.isLoading || !onlineOrder.rows) {
    return <Loading />;
  }
  if (!onlineOrder.isLoading && onlineOrder.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
        {renderSnackbar()}
      </div>
    );
  }
  return <Error message={onlineOrder?.message} />;
};

OnlineBill.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OnlineBill.defaultProps = {
  title: '',
  subtitle: '',
};

export default OnlineBill;
