/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
import { DateTime } from 'luxon';
import _ from 'lodash';
import THBText from 'thai-baht-text';

import qrcode from 'qrcode';
import {
  slipHeader,
  slipDefaultStyle,
  slipFooter,
  currencyFormatter,
} from './common';

const dateFormat = { day: 'numeric', month: 'long', year: 'numeric' };

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 */

export default function OrderNoVatSlip(
  customer,
  setting,
  serviceOrder,
  // discountorder,
  employee,
  paymentType,
  discount = 0,
  frompage,
  productTransaction,
  base64QR,
) {
  console.log('pdf serviceOrder', serviceOrder);
  console.log('pdf setting', setting);
  console.log('pdf customer', customer);
  console.log('pdf productTransaction', productTransaction);
  console.log('pdf paymentType', paymentType);
  console.log('pdf employee', employee);
  console.log('pdf discount', discount);
  // console.log('discountorder pdf', discountorder);

  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    mode: 'Alphanumeric',
    quality: 0.8,
    margin: 1,
    width: 250,
  };

  const genQR = (data) => {
    let resQR;
    qrcode.toDataURL(data, opts, (err, res) => {
      if (err) throw err;
      resQR = res;
    });
    return resQR;
  };
  const discountorder = _.sum(
    _.map(
      productTransaction?.order,
      (eachOrder) => eachOrder?.discount * eachOrder?.amount,
    ),
  );

  console.log('discountorder pdf', discountorder);

  const allPrice =
    _.sum(
      _.map(
        serviceOrder,
        (eachOrder) =>
          eachOrder?.price * (eachOrder?.amount || eachOrder?.quantity),
      ),
    ) || 0;
  const sumOrderdiscount = allPrice - discountorder;
  console.log('sumOrderdiscount', sumOrderdiscount);

  const vatNumber = (allPrice * setting?.tax_number) / 100;
  return {
    // pageSize: "RA4",
    pageSize: {
      width: 200,
      height: 'auto',
    },
    pageOrientation: 'portrait',
    pageMargins: [10, 146, 10, 100],
    header: slipHeader(
      setting,
      customer,
      employee,
      frompage,
      productTransaction,
    ),
    info: {
      title: 'ใบเสร็จรับเงิน',
    },
    defaultStyle: slipDefaultStyle,
    content: [
      {
        table: {
          headerRows: 1,
          widths: [30, 80, 30],
          body: [
            [
              {
                text: 'จำนวน',
                bold: true,
                border: [false, false, false, false],
                alignment: 'left',
                fontSize: 10,
              },
              {
                text: 'รายการ',
                bold: true,
                border: [false, false, false, false],
                alignment: 'left',
                fontSize: 10,
              },
              {
                text: 'ราคา',
                bold: true,
                border: [false, false, false, false],
                alignment: 'right',
                fontSize: 10,
              },
            ],
            // order lists
            // [
            //   {
            //     text: '1',
            //     bold: false,
            //     border: [false, false, false, false],
            //     alignment: 'left',
            //     fontSize: 10,
            //   },
            //   {
            //     text: 'สินค้าตัวที่ 1',
            //     bold: false,
            //     border: [false, false, false, false],
            //     alignment: 'center',
            //     fontSize: 10,
            //   },
            //   {
            //     text: '500.00',
            //     bold: false,
            //     border: [false, false, false, false],
            //     alignment: 'right',
            //     fontSize: 10,
            //   },
            // ],
            // [
            //   {
            //     text: '2',
            //     bold: false,
            //     border: [false, false, false, false],
            //     alignment: 'left',
            //     fontSize: 10,
            //   },
            //   {
            //     text: 'สินค้าตัวที่ 2',
            //     bold: false,
            //     border: [false, false, false, false],
            //     alignment: 'center',
            //     fontSize: 10,
            //   },
            //   {
            //     text: '200.00',
            //     bold: false,
            //     border: [false, false, false, false],
            //     alignment: 'right',
            //     fontSize: 10,
            //   },
            // ],
            ..._.map(serviceOrder, (eachProduct) => [
              {
                text: eachProduct?.amount || eachProduct?.quantity,
                bold: false,
                border: [false, false, false, false],
                alignment: 'left',
                fontSize: 10,
              },
              {
                text: eachProduct?.name || eachProduct?.product?.name,
                bold: false,
                border: [false, false, false, false],
                alignment: 'left',
                fontSize: 10,
              },
              {
                text: eachProduct?.price
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                bold: false,
                border: [false, false, false, false],
                alignment: 'right',
                fontSize: 10,
              },
            ]),
            discount && discount > 0
              ? [
                  {
                    text: _.size(serviceOrder) + 1,
                    bold: false,
                    border: [false, false, false, false],
                    alignment: 'left',
                    fontSize: 10,
                  },
                  {
                    text: 'ส่วนลด',
                    bold: false,
                    border: [false, false, false, false],
                    alignment: 'left',
                    fontSize: 10,
                  },
                  {
                    text: `-${discount
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    bold: false,
                    border: [false, false, false, false],
                    alignment: 'right',
                    fontSize: 10,
                  },
                ]
              : [
                  {
                    text: '',
                    border: [false, false, false, false],
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
            [
              {
                text: '--------------------------------------------------',
                colSpan: 3,
                bold: true,
                // margin: [0, 2],
                border: [false, false, false, false],
                fontSize: 8,
                alignment: 'center',
              },
            ],

            // ..._.map(serviceOrder?.service_list, (eachList, index) => [
            //   {
            //     text: index + 1 + _.size(serviceOrder?.list),
            //     margin: [0, 2],
            //     border: [false, false, false, false],
            //   },
            //   {
            //     text: `${eachList?.service?.name} x ${currencyFormatter.format(
            //       eachList?.amount,
            //     )}`,
            //     margin: [0, 2],
            //     border: [false, false, false, false],
            //   },
            //   {
            //     text: currencyFormatter.format(parseFloat(eachList?.price)),
            //     margin: [0, 2],
            //     alignment: 'right',
            //     border: [false, false, false, false],
            //   },
            // ]),
            // ..._.map(serviceOrder?.fb_orders, (eachList, index) => [
            //   {
            //     text:
            //       index +
            //       1 +
            //       _.size(serviceOrder?.list) +
            //       _.size(serviceOrder?.services),
            //     margin: [0, 2],
            //     border: [false, false, false, false],
            //   },
            //   {
            //     text: `${eachList?.remark || ''} (${DateTime.fromISO(
            //       eachList?.date,
            //     )
            //       .setLocale('th')
            //       .toLocaleString(dateFormat)})`,
            //     margin: [0, 2],
            //     border: [false, false, false, false],
            //   },
            //   {
            //     text: currencyFormatter.format(parseFloat(eachList?.price)),
            //     margin: [0, 2],
            //     alignment: 'right',
            //     border: [false, false, false, false],
            //   },
            // ]),
            // serviceOrder?.final_discount > 0
            //   ? [
            //       {
            //         stack: [
            //           { text: 'ราคาก่อนรวมส่วนลด', margin: [0, 2] },
            //           { text: 'ส่วนลดท้ายใบเสร็จ', margin: [0, 2] },
            //         ],
            //         colSpan: 2,
            //         border: [false, false, false, false],
            //       },
            //       {},

            //       {
            //         stack: [
            //           {
            //             text: currencyFormatter.format(
            //               priceBeforeFinalDiscount,
            //             ),
            //             margin: [0, 2],
            //             alignment: 'right',
            //             border: [false, false, false, false],
            //           },
            //           {
            //             text: currencyFormatter.format(
            //               serviceOrder?.final_discount,
            //             ),
            //             margin: [0, 2],
            //             alignment: 'right',
            //             border: [false, false, false, false],
            //           },
            //         ],
            //       },
            //     ]
            //   : [
            //       {
            //         text: '',
            //         border: [false, false, false, false],
            //         colSpan: 3,
            //       },
            //       {},
            //       {},
            //     ],
            [
              {
                text: `ราคารวมสินค้า : ${allPrice
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                colSpan: 3,
                bold: true,
                margin: [0, 2],
                border: [false, false, false, false],
                fontSize: 8,
                alignment: 'right',
              },
            ],
            setting?.tax_include
              ? [
                  {
                    text: '',
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: `มูลค่าก่อน vat : ${(allPrice - vatNumber)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ],
            setting?.tax_include
              ? [
                  {
                    text: '',
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: `คิดเป็นมูลค่าภาษี ${
                      setting?.tax_number
                    }% : ${vatNumber
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ],
            discount && discount > 0
              ? [
                  {
                    text: `ส่วนลด ${discount
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: '',
                    colSpan: 3,
                    bold: true,
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ],
            discountorder
              ? [
                  {
                    text: `ส่วนลด ${discountorder
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: '',
                    colSpan: 3,
                    bold: true,
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ],
            discountorder
              ? [
                  {
                    text: `รวมมูลค่า ${sumOrderdiscount
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 12,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: `รวมมูลค่า ${(allPrice - discount)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 12,
                    alignment: 'right',
                  },
                ],
            setting?.tax_include
              ? [
                  {
                    text: 'VAT(Include)',
                    colSpan: 3,
                    bold: true,
                    margin: [0, 0],
                    border: [false, false, false, false],
                    fontSize: 12,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: '',
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ],

            [
              {
                text: '--------------------------------------------------',
                colSpan: 3,
                bold: true,
                // margin: [0, 2],
                border: [false, false, false, false],
                fontSize: 8,
                alignment: 'center',
              },
            ],
            [
              {
                text: 'ชำระ',
                colSpan: 1,
                bold: true,
                margin: [0, 2],
                border: [false, false, false, false],
                fontSize: 10,
                alignment: 'left',
              },
              {
                text:
                  paymentType === 'transfer'
                    ? 'โอน'
                    : paymentType === 'other'
                    ? setting?.channelpayment?.namepay
                    : 'เงินสด',
                colSpan: 2,
                bold: true,
                margin: [0, 2],
                border: [false, false, false, false],
                fontSize: 10,
                alignment: 'right',
              },
            ],
            setting?.promptpay?.ShowQrCode
              ? [
                  {
                    alignment: 'center',
                    colSpan: 3,
                    image: 'QR',
                    width: 150,
                    opacity: 0.8,
                    margin: [0, 0, 0, 0],
                  },
                ]
              : [
                  {
                    text: '',
                    colSpan: 3,
                    bold: true,
                    margin: [0, 2],
                    border: [false, false, false, false],
                    fontSize: 8,
                    alignment: 'right',
                  },
                ],
            [
              {
                text: setting?.promptpay?.accountName,
                colSpan: 3,
                bold: true,
                // margin: [0, 2],
                border: [false, false, false, false],
                fontSize: 8,
                alignment: 'center',
              },
            ],
            [
              {
                text: setting?.promptpay?.accountNumber,
                colSpan: 3,
                bold: true,
                // margin: [0, 2],
                border: [false, false, false, false],
                fontSize: 8,
                alignment: 'center',
              },
            ],
          ],
        },

        margin: [0, 15, 0, 0],
        fontSize: 10,
      },
      ...slipFooter(),
    ],
    images: {
      // QR: setting?.promptpay?.QrCode?.url || '1234',
      QR: base64QR || setting?.promptpay?.QrCode?.url || '1234',
    },
  };
}
